import {
  createContext,
  useState,
  useContext,
  ReactNode,
  useCallback,
  useMemo,
} from "react";

type ModalType = "share" | "side-filters" | "share-loan-offers" | null;

interface ModalDetails {
  type: ModalType;
  isOpen: boolean;
  meta?: Record<string, any>;
}

interface ModalContextValue {
  modal: ModalDetails;
  openModal: (type: ModalType, meta?: Record<string, any> | undefined) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextValue | undefined>(undefined);

const modalDefaultState = {
  type: null,
  isOpen: false,
  meta: {},
};

const ModalProvider = ({children}: {children: ReactNode}) => {
  const [modal, setModal] = useState<ModalDetails>(modalDefaultState);

  const openModal = useCallback(
    (type: ModalType, meta?: Record<string, any>) => {
      setModal({
        type,
        meta,
        isOpen: !modal.isOpen,
      });
    },
    []
  );

  const closeModal = useCallback(() => {
    setModal(modalDefaultState);
  }, []);

  const value = useMemo(
    () => ({modal, openModal, closeModal}),
    [modal, openModal, closeModal]
  );

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  return context;
};

export {useModal, ModalProvider};
