import {ImageInfo} from "@components/general/ImageInfo";
import {backOfficeInfo} from "@utils/data";
import React, {useEffect} from "react";
import Boundaries from "../home/Boundaries";
import {Capabilities} from "../home/Capabilites";
import {Hero} from "../home/hero";
import {LendersLove} from "../home/LendersLove";
import {Masterd} from "../home/Masterd";
import {TrustUs} from "../home/TrustUs";
import {Word} from "../home/Word";
import {useDispatch, useSelector} from "react-redux";
import {fetchCountry} from "state/features/keywordCountrySlice";
import {AppDispatch, RootState} from "state/store";
import Loader from "@components/ui/loader/Loader";
import {EmptyState} from "../lenders/EmptyState";
import {capitalizeWords} from "@utils/formatAmount";
import {useRouter} from "next/router";
import {LatestBlog} from "../lenders/LatestBlog";
import providerImage from "../../../public/assets/images/provider-illus.svg";
import BestProviders from "../home/BestProviders";

const KeywordCountry = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {country, keyword, error, status} = useSelector(
    (state: RootState) => state.keywordCountry
  );

  const router = useRouter();

  useEffect(() => {
    const path = window.location.pathname;
    const lastSegment = path.split("/").pop();

    // Redirecting for Project X
    if (
      lastSegment?.includes("lender") ||
      lastSegment?.includes("provider") ||
      lastSegment?.includes("offer")
    ) {
      switch (lastSegment) {
        case "lender":
          router.push("/lenders");
          break;
        case "provider":
          router.push("/providers");
          break;
        case "offer":
          router.push("/offers");
      }

      return;
    } else {
      dispatch(fetchCountry());
    }
  }, []);

  if (status === "loading") return <Loader message="Loading..." />;
  else if (error) return <EmptyState title="No results found" />;

  return (
    <>
      {status === "succeeded" && country && keyword && (
        <>
          <Hero
            title={`The best ${keyword} in ${capitalizeWords(
              country as string
            )}`}
            description={`Lending is profitable but also difficult but not with Lendsqr’s ${keyword}! Start, launch and scale your loan business easily across multiple channels and make smart decisions all at a fraction of the cost!`}
            heroImage="assets/images/HeroIllustration.svg"
            primaryText="Get started free"
            heroPosition="flex-end"
          />

          <TrustUs />

          <Capabilities />

          <ImageInfo
            data1={[backOfficeInfo[0], backOfficeInfo[1]]}
            data2={[backOfficeInfo[2], backOfficeInfo[3]]}
            data3={backOfficeInfo[4]}
            title="Manage your entire lending operations all-in-one place"
            description="With Lendsqr’s advanced loan management back office, you gain total control and clarity over every aspect of your loan business."
          />

          <Boundaries />

          <Masterd />

          <LendersLove />

          <Word />
          <BestProviders country={country ?? ""} />
          <LatestBlog />
        </>
      )}
      {/* TODO - Add FAQs section */}
    </>
  );
};

export default KeywordCountry;
